import React from "react";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";

import { 
  StyledBusinessClientReviews,
  StyledBusinessClientReviewsItemHeader,
  StyledBusinessClientReviewsItems,
  StyledBusinessClientReviewsItem,
  StyledBusinessClientReviewsItemImage,
  StyledBusinessClientReviewsItemName,
  StyledBusinessClientReviewsItemRole,
  StyledBusinessClientReviewsItemCorporate,
  StyledBusinessClientReviewsItemDescription,
} from "./style";

const BusinessClientReviews = ({ reviews }) => {
  return (
    <StyledGridRow isFullMobile>
      <StyledBusinessClientReviews>
        <Scroller>
          <StyledBusinessClientReviewsItems>
            {reviews.map((item, index) => (
              <BusinessClientReview key={index} review={item}/>
            ))}
          </StyledBusinessClientReviewsItems>
        </Scroller>
      </StyledBusinessClientReviews>
    </StyledGridRow>
  );
};

export const BusinessClientReview = ({ review }) => {
  return (
    <StyledBusinessClientReviewsItem>
      <StyledBusinessClientReviewsItemHeader>
        <StyledBusinessClientReviewsItemImage src={review.avatar} loading="lazy" />
        <div>
          <StyledBusinessClientReviewsItemName
            dangerouslySetInnerHTML={{ __html: review.name }}
          />
          {review.role
            && <StyledBusinessClientReviewsItemRole
              dangerouslySetInnerHTML={{ __html: review.role }}
            />
          }
          <StyledBusinessClientReviewsItemCorporate
            dangerouslySetInnerHTML={{ __html: review.corporate }}
          />
        </div>
      </StyledBusinessClientReviewsItemHeader>
      <StyledBusinessClientReviewsItemDescription
        dangerouslySetInnerHTML={{ __html: review.description }}
      />
    </StyledBusinessClientReviewsItem>
  );
};

export default BusinessClientReviews;
